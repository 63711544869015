import { GatsbyButton } from '@components/atm.button/button.component';
import { ChatfuelBroadcastRequest } from '@global/utils/chat/chatfuel';
import { getGenderSuffix, SleepDiaryTechnique } from '@global/utils/domain/entities';
import { getBeforeSleepFactorsInfo, getDuringSleepFactorsInfo, getTechniquesInfo } from '@global/utils/domain/sleep-factor';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { User } from '@global/utils/remote-graphql-types';
import { UseGetUserOutput } from '@lp-root/src/components/obj.custom-hooks/user-info.hook';
import { ConfigurationTemplate } from '@lp-root/src/modules/configuration/components/configuration-template.component';
import { ScheduleConfigFormContainer } from '@lp-root/src/modules/configuration/components/schedule-config-form-container.component';
import { TrackerAndDiaryConfigurationFields } from '@lp-root/src/modules/configuration/components/schedule-config-form.component';
import { SleepFactorsConfigurationFields } from '@lp-root/src/modules/configuration/components/sleep-factors-configuration-fields.component';
import { TechniqueConfigurationField } from '@lp-root/src/modules/configuration/components/technique-configuration-field.component';
import { Col, Row, Separator } from '@web/atomic';
import { StickButtonWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { Form, FormData } from '@web/atomic/legacy/obj.form';
import { NativeHelper } from '@web/data/native.helper';
import { usePostUsingMessenger } from '@web/data/use-post-using-messenger.hook';
import { PostUrl } from '@web/data/vigilantes.datasource';
import { graphql } from 'gatsby';
import React, { useCallback } from 'react';
import { buildPostConfigRequest, ConfigurationRequestInput } from '../../data/request-utils/config.request';
import { PageProps } from '../../utils/local-types';

export type ConfigurationPageFormData = ConfigurationRequestInput;

const ConfiguracaoDiarioPage: React.FunctionComponent<PageProps> = (props) => {
  return <ConfigurationTemplate>{(user) => <ConfigFormRow user={user} />}</ConfigurationTemplate>;
};

// ------------------------------------------------------------------------------------------------------

interface ConfigFormProps {
  user: UseGetUserOutput;
}

const ConfigFormRow: React.FunctionComponent<ConfigFormProps> = (props) => {
  const [submitUserConfig, { loading: updateUserLoading }] = useUpdateUserRequest();

  const beforeSleepFactors = getBeforeSleepFactorsInfo(props.user);
  const duringSleepFactors = getDuringSleepFactorsInfo(props.user);
  const techniques = getTechniquesInfo(props.user).filter((factor) => !isNullOrUndefined(factor.enable));

  const handleSubmit = useCallback(
    async (formData: FormData<ConfigurationPageFormData>) => {
      if (Object.keys(formData.error).length !== 0) return;
      const request = buildPostConfigRequest(
        props.user.id,
        formData.data,
        techniques.map((item) => item.id as SleepDiaryTechnique)
      );
      await submitUserConfig(request);
    },
    [props.user.id, submitUserConfig, techniques]
  );

  return (
    <Row>
      <Col xs={12} sm={12} md={7} lg={7}>
        <Form onSubmit={handleSubmit}>
          <ScheduleConfigFormContainer userId={props.user.id}>
            {({
              initialSleepDiaryReminder,
              initialSleepDiaryReminderEnabled,
              initialSleepTrackerReminderEnabled,
              initialSleepTrackerReminder,
            }) => (
              <TrackerAndDiaryConfigurationFields
                initialSleepDiaryReminder={initialSleepDiaryReminder}
                initialSleepDiaryReminderEnabled={initialSleepDiaryReminderEnabled}
                initialSleepTrackerReminderEnabled={initialSleepTrackerReminderEnabled}
                initialSleepTrackerReminder={initialSleepTrackerReminder}
              />
            )}
          </ScheduleConfigFormContainer>

          <SleepFactorsConfigurationFields beforeSleepFactors={beforeSleepFactors} duringSleepFactors={duringSleepFactors} />

          <TechniqueConfigurationField techniques={techniques} />

          <StickButtonWrapper>
            <Row mb center={'xs'}>
              <Col xs={12} sm={12} md={4} lg={4}>
                <GatsbyButton type="submit" kind="primary" loading={updateUserLoading} expanded>
                  Salvar configurações
                </GatsbyButton>
              </Col>
            </Row>
          </StickButtonWrapper>
          <Separator />
        </Form>
      </Col>
    </Row>
  );
};

const useUpdateUserRequest = () => {
  const handleSuccess = useCallback(() => {
    flashDispatcherService.dispatchMessage('Configuração alterada com sucesso!', 'success');
  }, []);

  return usePostUsingMessenger({ url: PostUrl.UpdateUser, onSuccess: handleSuccess });
};

export default ConfiguracaoDiarioPage;

export const query = graphql`
  query ConfiguracaoDiarioPage {
    site {
      ...SiteUrl
    }
  }
`;
